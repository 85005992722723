
// Lib
import React from 'react'

// Styles
import '../styles/Checkout.css'

// Components
import CheckoutProduct from './CheckoutProduct';
import { useStateValue } from './StateProvider';
import Subtotal from './Subtotal'




function Checkout() {
    const [{basket, user}, dispatch] = useStateValue();
    return ( 
        <div className='checkout'>
            <div className='checkout__top'>
                <div>
                    <h3>{user?.email}</h3>
                    <h2 className='checkout__title'>Your shopping basket</h2>
                    {basket.map((item)=>(
                        <CheckoutProduct 
                            id={item.id}
                            title={item.title}
                            price={item.price}
                            image={item.image}
                            rating={item.rating}
                        />
                    ))}                    
                </div>
            </div>
            <div className='checkout__bottom'>
                
                {basket.length==0 ? 
                    <h2 className='checkout__warningText'>Shopping basket is empty</h2>: 
                    (<>
                        <hr/>
                        <Subtotal />
                    </>)
                }
                
            </div>
        </div>
  )
}

export default Checkout