
// Libs
import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

// Styles
import '../styles/Login.css';

// Component
import { auth } from './firebase';

    function Login() {
        const navigate = useNavigate();
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        
        const signIn = e => {
            e.preventDefault();
            signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                navigate('/');
            })
            .catch((error) => {
                alert(error.message);
            });
            
        }

        // const register = e => {
        //     e.preventDefault();
        //     createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
        //         if (auth) {
        //             navigate('/');
        //         }
        //         console.log(userCredential);
        //       })
        //       .catch((error) => {
        //         console.log(error.message);
        //       });;
        // }

        return (
        <div className='login' style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/background-4.jpg'})`}}>
            
            <Link to='/' style={{ textDecoration: 'none' }}>
                <div className='login__brandName'>
                    <text>Kangming's Shop</text>
                </div>
            </Link>

            <div className='login__container'>
                <h1>Sign in now</h1>
                <form>
                    <h5>E-mail</h5>
                    <input type='email' onChange={e=>setEmail(e.target.value)} value={email} />

                    <h5>Password</h5>
                    <input type='password' onChange={e=>setPassword(e.target.value)} value={password} />

                    <button className='login__signInButton' type='submit' onClick={signIn}>Sign In</button>
                </form>

                <p>
                    By continuing, you agree to Kangming's Shop Conditions of Use and
                    Privacy Notice.
                </p>

                <hr></hr>

                <button className='login__registerButton' onClick={() => navigate('/register')}>Create Your Account</button>

            </div>
        </div>
        )
    }

export default Login