// Libs
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import CurrencyFormat from 'react-currency-format';
import { connectFirestoreEmulator, doc, setDoc } from "firebase/firestore"; 

// Styles
import '../styles/Payment.css';

// Components
import { useStateValue } from './StateProvider';
import CheckoutProduct from './CheckoutProduct';
import { getBasketTotal } from './reducer';
import axios from './axios';
import {db} from './firebase';
import AddressForm from './AddressForm';


function Payment() {
    const [{basket, user}, dispatch] = useStateValue();

    console.log("USER==>", user);

    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

    const [error, setError] = useState(null);
    const [disabled, setDisabled] = useState(true);
    const [succeeded, setSucceeded] = useState(false);
    const [processing, setProcessing] = useState('');
    const [clientSecret, setClientSecret] = useState(true);
    
    useEffect(() => {
        // Generate a special stripe secret which allow us to charge a customer
        console.log('USE EFFECT!');
        const getClientSecret = async() => {
            console.log("REQUIRE POST");
            const response = await axios({
                method: 'post',
                url: `/payments/create?total=${Math.round(getBasketTotal(basket) * 100)}`,
                headers: {"Access-Control-Allow-Origin": "*"}
            })
            setClientSecret(response.data.clientSecret)
        }

        getClientSecret();
        console.log(clientSecret);
    }, [basket])

    console.log('THE SECRET IS >>>', clientSecret);

    const handleSubmit = async (event) => {
        // Do all the fancy stripe
        event.preventDefault();
        setProcessing(true);

        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                card: elements.getElement(CardElement)
            }
        }).then(({ paymentIntent }) => {
            const paymentRef = doc(db, "users", user?.uid, "orders", paymentIntent.id);
            setDoc(paymentRef, {
                basket: basket,
                amount: paymentIntent.amount,
                created: paymentIntent.created,
            });

            setSucceeded(true);
            setError(null);
            setProcessing(false);

            dispatch({
                type: 'EMPTY_BASKET',
            });

            navigate('/orders', {replace: true});
            
        });
    }

    const handleChange = event => {
        // Listen for change in the CardElement
        // Display any errors as the customer types their card detail
        setDisabled(event.empty);
        setError(event.error ? event.error.message : "");
    }



    return (
        <div className='payment'>
            <div className='payment__container'>
                <h1>Checkout (<Link to='/checkout'>{basket?.length} items</Link>)</h1>
                <div className='payment__section'>
                    <div className='payment__title'>
                        <h3>Delivery Address</h3>
                    </div>
                    {/* <div className='payment__address'>
                        <p>{user?.email}</p>
                        <p>4650 Van Buren St</p>
                        <p>Riverdale, MD</p>
                    </div> */}
                    <div className='payment__address'>
                        <AddressForm />
                    </div>
                    
                </div>
                <div className='payment__section'>
                    <div className='payment__title'>
                        <h3>Review Items</h3>
                    </div>
                    <div className='payment__item'>
                        {/* All the products in basket */}
                        {basket.map((item) => {
                            return <CheckoutProduct 
                                id={item.id}
                                title={item.title}
                                price={item.price}
                                image={item.image}
                                rating={item.rating}
                            />
                        })}
                    </div>
                </div>


                <div className='payment__section'>
                    <div className='payment__title'>
                        <h3>Payment Method</h3>
                    </div>
                    <div className='payment__detail'>
                        {/* Stripe magic */}
                        <form onSubmit={handleSubmit}>
                            <CardElement  onChange={handleChange}/>

                            {/* <SplitForm /> */}

                            <div className='payment__priceContainer'>
                                <div className='payment__price'>
                                    <CurrencyFormat
                                        renderText={(value) => (
                                            <text>Order Total: {value}</text>
                                        )}
                                        decimalScale={2}
                                        value={getBasketTotal(basket)}
                                        displayType={"text"}
                                        thousandSeparator={true}
                                        prefix={"$"}
                                    />
                                </div>

                                <button disabled={processing || disabled || succeeded}>
                                    <span>{processing ? <p>Processing</p> : 'Buy Now'}</span>
                                </button>
                            </div>

                            {/* Errors */}
                            {error && <div>{error}</div>}
                        </form>

                    </div>
                </div>
            </div>
        </div>
    );

}

export default Payment;