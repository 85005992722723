
// Libs
import React from "react";
import SearchIcon from '@mui/icons-material/Search';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Link } from "react-router-dom";
import { signOut } from "firebase/auth";

// Styles
import '../styles/Header.css';

// Components
import { useStateValue } from "./StateProvider";
import {auth} from './firebase';


function Header(){

    const [{ basket, user }, dispatch] = useStateValue();

    const handleAuthentication = () => {
        if (user) {
            signOut(auth).then(() => {
                // Sign-out successfully
            }).catch((error) => {
                // Error happen
            });
        }
    }

    return (
        <div className='header'>
            
            <Link to='/' style={{ textDecoration: 'none' }}>
                <text className='header__brandName'>Kangming's Shop</text>
            </Link>
            

            {/* <div className='header__search'>
                <input className='header__searchInput' type='text' />
                <SearchIcon className='header__searchIcon' />
            </div> */}


            <div className='header__nav'>
                <div className="header__option">
                    <span className='header__optionLineTwo'>{user?`Hello, ${user.email}`:null}</span>
                </div>
                
                <Link to={!user && '/login'} style={{ textDecoration: 'none' }}>
                    <div onClick={handleAuthentication} className='header__option'>
                        {/* <span className='header__optionLineOne'>Hello {user ? user.email:'Guest'}</span> */}
                        <span className='header__optionLineTwo'>{user ? 'Sign out':'Sign in / Sign up'}</span>
                    </div>
                </Link>
                
                <Link to='/orders' style={{ textDecoration: 'none' }}>
                    <div className='header__option'>
                        {/* <span className='header__optionLineOne'>Returns</span> */}
                        <span className='header__optionLineTwo'>Orders</span>
                    </div>
                </Link>
            

                <Link to='/checkout' style={{ textDecoration: 'none' }}>
                    <div className='header__optionBasket'>
                        <ShoppingCartIcon />
                        <span className='header__optionLinkTwo header__basketCount'>{basket?.length}</span>
                    </div>
                </Link>
                
            </div>
        </div>
    );
}

export default Header;