
// Libs
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CurrencyFormat from 'react-currency-format';

// Styles
import '../styles/Subtotal.css';

// Components
import { useStateValue } from './StateProvider';
import { getBasketTotal } from './reducer';


function Subtotal() {
    const [{ basket, user }, dispatch] = useStateValue();
    const navigate = useNavigate();

    const handleOnClick = (e) => {
        if (!user) {
            navigate('/login')
        }else{
            navigate('/payment')
        }
    } 

    return (
        <div className='subtotal'>

            <CurrencyFormat
                renderText={(value) => (
                <>
                    <p>
                        Subtotal ({basket.length} items): <strong>{value}</strong>
                    </p>
                    <small className="subtotal__gift">
                        <input type="checkbox" /> This order contains a gift
                    </small>
                </>
                )}
                decimalScale={2}
                value={getBasketTotal(basket)}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
            />
            <button onClick={handleOnClick}>Proceed to Checkout</button>
        </div>
    )
}

export default Subtotal