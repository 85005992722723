// Lib
import React from 'react'
import {useParams} from 'react-router-dom';
// import Collapsible from 'react-collapsible';

// Styles
import '../styles/ProductDetail.css';

import {myInventory} from '../inventory';
import Collapsible from './Collapsible';
import Footer from './Footer';
import { useStateValue } from './StateProvider';

function ProductDetail() {

    // Grab product info from inventory
    let { id } = useParams();
    const product = myInventory.find(item => item.id === id)
    const {title, price, rating, image, overview, specifications, reviews} = product

    const [{ basket }, dispatch] = useStateValue();
    const addToBasket = () => {
        // dispatch the item to data layer
        dispatch({
        type: "ADD_TO_BASKET",
        item: {
            id: id,
            title: title,
            image: image,
            price: price,
            rating: rating
        }
        });
    };


    return (
        <div className='productDetail'>
            <div className='productDetail__productCard'>
                <div className='productDetail__info'>
                    <text className='productDetail__title'>{title}</text>
                    <div className='productDetail__rating'>
                        {Array(rating).fill().map((_, i)=>{
                            return (<p>💜</p>)
                        })}
                        <text>({rating}/5)</text>
                    </div>
                    <img className='productDetail__image' src={image} />
                    <div className='productDetail__middle'>
                        <p className='productDetail__price'>
                            <small>$</small>
                            <strong>{price}</strong>
                        </p>
                        <button onClick={addToBasket}>Add to Cart</button>
                    </div>
                    
                    <Collapsible trigger='Overview' content={overview} />
                    <Collapsible trigger='Specifications' content={specifications} />
                    <Collapsible trigger='Reviews' content={reviews} />
                    <hr/>
                </div>
            </div>
            <Footer />
            
        </div>
    )
}

export default ProductDetail