import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Collapse from 'react-bootstrap/Collapse';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

import '../styles/Collapsible.css';

function Collapsible({trigger, content}) {
  const [open, setOpen] = useState(false);

  const listContent = () => {
    if (Array.isArray(content)){
      return (<ol>
        {content.map((item)=>{
          return(<li>
            {item}
          </li>)
        })}
      </ol>)
    }else{
      return (<ul>
        {Object.keys(content).map((key) => {
          return (<li>
            <strong>{key + ': '}</strong>
            <p>{content[key]}</p>
          </li>)
        })}
      </ul>)
    }
  }

  return (
    <>
      <button
        className='collapsible__triggerButton'
        onClick={() => setOpen(!open)}
        aria-controls="content"
        aria-expanded={open}
      >
        <span className='collapsible__trigger'>{trigger} {!open ? <ArrowDropDownIcon/> : <ArrowDropUpIcon/>} </span>
      </button>
      <Collapse in={open}>
        <div>
          <span className="collapsible__content">
            {typeof(content)==='string'? content: listContent()}
          </span>
        </div>
      </Collapse>
    </>
  );
}

export default Collapsible;