
// Libs
import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


import '../styles/GridSystem.css';

// Components
import Product from './Product';

// Data
import {myInventory} from '../inventory';

function GridSystem() {

    const numOfCol = Math.ceil(myInventory/3);
    const numOfRow = Array(3).fill();

    return (
        <div className='gridSystem'>
            <Container>
                <Row lg='3'>
                    
                        {myInventory.map((item) => {
                            return (
                                <Col lg='4' md='6' sm='12'>{
                                    <Product 
                                    id={item.id}
                                    title={item.title}
                                    price={item.price}
                                    image={item.image}
                                    rating={item.rating}
                                    />
                                }</Col>
                            )
                        })}
                </Row>
            </Container>
        </div>
        
    )
}

export default GridSystem