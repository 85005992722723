// Libs
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import { useEffect } from 'react';
import { onAuthStateChanged } from "firebase/auth";
import { loadStripe} from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import WebFont from 'webfontloader';

// Styling
import './styles/App.css';

// Components
import Header from './components/Header';
import Home from './components/Home';
import Orders from './components/Orders';
import Checkout from './components/Checkout';
import Login from './components/Login';
import {auth} from './components/firebase';
import Payment from './components/Payment';
import { useStateValue } from './components/StateProvider';
import Footer from './components/Footer';
import Register from './components/Register';
import ProductDetail from './components/ProductDetail';




// Stripe Promise
const promise = loadStripe('pk_test_51LPGKhGIFIXFCEWgkQvHcK4kzjrCR0xqehoLZcTaPVDAGkLDq0f5L8lVgcimYaAnAgzhs94gAwTKujuGqiUCtVaw00jQLqt8To');

function App() {

  const [{}, dispatch] = useStateValue();

  useEffect(() => {
    // Only run once when the app comments loads...
    onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is or was signed in
        dispatch({
          type: 'SET_USER',
          user: user
        })
      } else {
        // User is signed out
        dispatch({
          type: 'SET_USER',
          user: null
        })
      }
    });
  }, []);

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Droid Sans', 'Pacifico', 'Arial']
      }
    });
   }, []);

  return (

    // BEM
    <Router>
      <div className="App">
        <Routes>
          <Route path='/' element={<div><Header /> <Home /><Footer /></div>} />
          <Route path='/checkout' element={<div><Header /> <Checkout /></div>} />
          <Route path='/login' element={<div><Login /></div>} />
          <Route path='/register' element={<div><Register /></div>} />
          <Route path='/product/:id' element={<div><Header/><ProductDetail/></div>} />
          <Route path='/payment' element={<div>
            <Header />
            <Elements stripe={promise}>
              <Payment />
            </Elements>
          </div>} />
          <Route path='/orders' element={<div><Header /><Orders /></div>}/>
        </Routes>
        
      </div>
    </Router>
    
  );
}

export default App;
