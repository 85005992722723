// Libs
import React, { useEffect, useState } from 'react';
import { doc, collection, getDoc, query, orderBy, onSnapshot } from "firebase/firestore";

// Styles
import '../styles/Orders.css';

// Components
import { useStateValue } from './StateProvider';
import Order from './Order';
import { db } from './firebase';
import { Link, useNavigate } from 'react-router-dom';

function Orders() {
    const [{ basket, user }, dispatch] = useStateValue();
    const [orders, setOrders] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        if (user){
            const userRef = doc(db, 'users', user.uid);
            const q = query(collection(userRef, 'orders'), orderBy("created", "desc"))
            onSnapshot(q, (snapshot) => {
                setOrders(snapshot.docs.map(d => (
                    {
                        id: d.id,
                        data: d.data()
                    }
                )))
            })
        }else{
            setOrders([])
        }
    }, [user])

    const renderOrders = () => {
        if (!user){
            return <h2 style={{textAlign:'center', color:'gray', marginTop:'20px'}}>
                        Please sign in to view your orders.
                    </h2>
        }else{
            if(orders.length == 0){
                return (<>
                    <h2>Your Orders</h2>
                    <hr></hr>
                    <h2 style={{textAlign:'center', color:'gray', marginTop:'20px'}}>
                        Your don't have any order history. <Link to='/' style={{textDecoration:'none'}}>Go shopping now!</Link>
                    </h2>
                </>)
            }else{
                return (<>
                    <h2>Your Orders</h2>
                    <div className='orders__order'>
                        {orders?.map(order => (
                    <Order order={order} />
                ))}
                    </div>
                </>)
            }        
        }
    }

    return (
        <div className='orders'>
            {renderOrders()}
        </div>
    )
}

export default Orders