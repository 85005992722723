// Lib
import React from 'react'

// Styles
import '../styles/CheckoutProduct.css';

// Components
import { useStateValue } from './StateProvider';


function CheckoutProduct({id, image, title, price, rating, hideButton}) {

    const [{basket}, dispatch] = useStateValue();
    const removeFromBasket = () => {
        // remove the item from basket
        dispatch({
            type: 'REMOVE_FROM_BASKET',
            id: id,
        })
    }
    return (
        <div className='checkoutProduct'>
            <img className='checkoutProduct__image' src={image} />
            <div className='checkoutProduct__info'>
                <p className='checkoutProduct__title'>{title}</p>
                <p className='checkoutProduct__price'>
                    <strong>${price}</strong>
                </p>
                <div className='checkoutProduct_rating'>
                    {Array(rating).fill().map((_, i)=>(
                        <p>💜</p>
                    ))}
                </div>
                {!hideButton && <button onClick={removeFromBasket}>Remove from basket</button>}
            </div>
        </div>
  )
}

export default CheckoutProduct