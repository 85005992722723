
// Lib
import React, { useState } from 'react'

// Styles
import '../styles/AddressForm.css';

function AddressForm() {

    const [addressInfo, setAddressInfo] = useState({
        line1: '',
        line2: '',
        city: '',
        state: '',
        zipCode: ''
    });

    const handleChange = (e) => {
        const { value, name } = e.target;
        console.log(value, name);
        setAddressInfo(prev => ({
            ...prev,
            [name]:value
        })) 
    }

    return (
        <form className='addressForm'>
            <label for='line1'>Address Line 1</label>
            <input onChange={handleChange} name='line1' id='line1' value={addressInfo.line1} type='text'></input>

            <label for='line2'>Address Line 2</label>
            <input onChange={handleChange} name='line2' id='line2' value={addressInfo.line2} type='text'></input>

            <label for='city'>City</label>
            <input onChange={handleChange} name='city' id='city' value={addressInfo.city} type='text'></input>

            <label for='state'>State</label>
            <input onChange={handleChange} name='state' id='state' value={addressInfo.state} type='text'></input>

            <label for='zipCode'>Zip Code</label>
            <input onChange={handleChange} name='zipCode' id='zipCode' value={addressInfo.zipCode} maxLength={5} type='text'></input>

        </form>
    )
}

export default AddressForm