
// Libs
import React from 'react'
import { Link } from 'react-router-dom';

// Styles
import '../styles/Product.css'

// Component
import { useStateValue } from './StateProvider';

function Product({id, title, image, price, rating}) {
    const [{ basket }, dispatch] = useStateValue();
    const addToBasket = () => {
        // dispatch the item to data layer
        dispatch({
        type: "ADD_TO_BASKET",
        item: {
            id: id,
            title: title,
            image: image,
            price: price,
            rating: rating
        }
        });
    };

    // const truncateTitle = () => {
    //     var newTitle = title.substring(0, 100)
    //     newTitle += ' ...';
    //     return newTitle
    // }

    return (
        <div className='product'>
            <img src={image}/>
            <div className='product__info'>
                {/* <p className='product__title'>{truncateTitle()}</p> */}

                 {/* To detail product page */}
                <Link to={'/product/' + id} style={{textDecoration: 'none', fontSize:'1px'}}>
                    <p className='product__title'>{title}</p>
                </Link>
                
                <p className='product__price'>
                    <small>$</small>
                    <strong>{price}</strong>
                </p>
                <div className='product__rating'>
                    {Array(rating).fill().map((_, i)=>{
                        return (<p>💜</p>)
                    })}   
                </div>
            </div>
            
            <button onClick={addToBasket}>Add to Basket</button>
        </div>
    );
}

export default Product;