// Lib
import React, { useState } from 'react'
import { Link, useNavigate } from "react-router-dom";
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from "firebase/auth";

// Styles
import '../styles/Register.css';

import { auth } from './firebase';

    function Register() {
        const navigate = useNavigate();
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [name, setName] = useState('');
        
        // const signIn = e => {
        //     e.preventDefault();
        //     signInWithEmailAndPassword(auth, email, password)
        //     .then((userCredential) => {
        //         navigate('/');
        //     })
        //     .catch((error) => {
        //         alert(error.message);
        //     });
            
        // }

        const register = e => {
            e.preventDefault();
            createUserWithEmailAndPassword(auth, email, password).then((userCredential) => {
                if (auth) {
                    navigate('/');
                }
                console.log(userCredential);
              })
              .catch((error) => {
                alert('The account has been registered. Please login.');
              });;
        }

        return (
        <div className='register' style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/background-6.jpg'})`}}>
            
            <Link to='/' style={{ textDecoration: 'none' }}>
                <div className='register__brandName'>
                    <text>Kangming's Shop</text>
                </div>
            </Link>

            <div className='register__container'>
                <h1>Create account</h1>
                <form>
                    <h5>Your name</h5>
                    <input type='text' onChange={e=>setName(e.target.value)} value={name} />

                    <h5>E-mail</h5>
                    <input type='email' onChange={e=>setEmail(e.target.value)} value={email} />

                    <h5>Password</h5>
                    <input type='password' onChange={e=>setPassword(e.target.value)} value={password} />

                    <button className='register__registerButton' type='submit' onClick={register}>Create</button>
                </form>

                <p>
                    By continuing, you agree to Kangming's Shop Conditions of Use and
                    Privacy Notice.
                </p>
                <hr></hr>
                <p>Already have an account?<Link to='/login'><text> Sign in</text></Link></p>
            </div>
        </div>
        )
    }


export default Register