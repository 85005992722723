// Lib
import React from 'react'

// Styles
import '../styles/Footer.css'

function Footer() {
    return (
        <div className='footer'>
            <div className='footer__text'>
                <p>Made with ❤️ by Kangming Luo</p>
                <p>Copyright © 2022 Kangming Luo</p>
            </div>
        </div>
    )
}

export default Footer