
// Lib
import React from 'react'

// Styles
import '../styles/Home.css';
import Footer from './Footer';

// Components
import GridSystem from './GridSystem';




function Home() {

    return (
        <div className='home'>
            {/* <div className='home__container'>
                <img className='home__image' src={process.env.PUBLIC_URL + '/img/home-background-2.jpg'} />

            </div> */}
            <artice 
                className='home__container'
                style={{backgroundImage: `url(${process.env.PUBLIC_URL + '/img/home-background-2.jpg'})`}}>
                <div className='home__welcomeText'>
                    <h1>Welcome!</h1>
                    <p>This is not only a <b>FULLY FUNCTIONAL</b> shopping website!</p>
                    <p>Explore to find out!</p>
                </div>
            </artice>

            <GridSystem />
        </div>
    )
}

export default Home