import {initializeApp} from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
  apiKey: "AIzaSyDQpXx8hEnylMGxtRSsCEfIzVJBePeTHAA",
  authDomain: "my-shopping-website-d54bb.firebaseapp.com",
  projectId: "my-shopping-website-d54bb",
  storageBucket: "my-shopping-website-d54bb.appspot.com",
  messagingSenderId: "330123183169",
  appId: "1:330123183169:web:2207fe9ab1d8b630e632c0"
};


const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore(firebaseApp)
const auth = getAuth(firebaseApp);

export {db, auth};  